<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-process
            type="process"
            label="공정"
            name="processCd"
            v-model="searchParam.processCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 점검년월(예정, 완료) -->
          <c-datepicker
            label="점검년월"
            name="month"
            type="month"
            v-model="searchParam.month">
          </c-datepicker>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 진행상태 -->
          <c-select
            type="search"
            stepperGrpCd="MIM_DAILY_STATUS_CD"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="checkStatusCd"
            label="진행상태"
            v-model="searchParam.checkStatusCd"
          ></c-select>
        </div>
        <!-- 점검일 -->
        <!-- <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            :range="true"
            name="period"
            label="점검완료일"
            v-model="period"
          ></c-datepicker>
        </div> -->
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="일상점검 목록"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      :checkClickFlag="false"
      rowKey="minEquipmentCheckId"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group>
            <c-btn label="등록" v-if="editable" icon="add" @btnClicked="onItemClick('B')" />
            <c-btn label="검색" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </div>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: "equipment-daily_inspection",
  props: {
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: "plantName",
            field: "plantName",
            style: 'width:100px',
            label: "사업장",
            align: "center",
            sortable: false,
          },
          {
            name: "processName",
            field: "processName",
            style: 'width:80px',
            label: "공정",
            align: "center",
            sortable: false,
          },
          {
            name: "dailyName",
            field: "dailyName",
            style: 'width:200px',
            label: "점검명",
            align: "left",
            type: "link",
            sortable: false,
          },
          {
            name: "checkStatusName",
            field: "checkStatusName",
            label: "진행단계",
            style: 'width:100px',
            align: "center",
            sortable: false,
          },
          {
            name: 'approvalStatusName',
            field: 'approvalStatusName',
            label: '결재진행단계',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: "checkDate",
            field: "checkDate",
            style: 'width:100px',
            label: "점검월",
            align: "center",
            sortable: false,
          },
          {
            name: "checkUserName",
            field: "checkUserName",
            style: 'width:100px',
            label: "점검자",
            align: "center",
            sortable: false,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        month: '',
        processCd: '',
        utilityFlag: 'N',
      },
      period: [ '', ''],
      editable: true,
      listUrl: '',
      deleteUrl: '',
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      isResult: false,
      selected: [],
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sop.min.equipment.plan.daily.list.url;
      this.searchParam.month = this.$comm.getThisMonth();
      this.getList();
    },
    getList() {
      // 전체조회
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    linkClick(row, col, index) {
      this.popupOptions.param = {
        dailyCheckId: row ? row.dailyCheckId : '',
      };
      this.popupOptions.title = '일상점검 상세'; 
      this.popupOptions.target = () =>
        import(`${"./equipmentDailyDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    onItemClick(type) {
      this.linkClick(null, type);
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.$refs['table'].selected = [];
      if (type === 'COMPLETE' || type === 'SAVE') {
        window.getApp.$emit('APP_REQUEST_SUCCESS');
      }
      this.getList();
    },
  },
};
</script>
<style>
.blinking {
  -webkit-animation:blink 3s ease-in-out infinite alternate;
  -moz-animation:blink 3s ease-in-out infinite alternate;
  animation:blink 3s ease-in-out infinite alternate;
}
@-webkit-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@-moz-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
</style>